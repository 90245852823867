import { useEffect } from 'react';

const TawkWidget: React.FC = () => {
  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    var Tawk_API = Tawk_API || {},
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Tawk_LoadStart = new Date();

    const tawk = document.createElement('script');
    tawk.async = true;
    tawk.src = 'https://embed.tawk.to/665ee2bc9a809f19fb38cfe9/1hvhabcar';
    tawk.charset = 'UTF-8';
    tawk.setAttribute('crossorigin', '*');
    setTimeout(() => {
      document.head.appendChild(tawk);
    }, 5000);

    return () => {
      if (document.head.contains(tawk)) {
        document.head.removeChild(tawk);
      }
    };
  });

  return null;
};

export default TawkWidget;
