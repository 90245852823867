import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import { strapi } from '~/lib/strapi/client.server';
import { cache } from '~/lib/cache/client.server';
import { kiyoh } from '~/lib/kiyoh/client.server';
import type { NavbarContext } from '~/modules/layout/components/nav/nav-provider';
import NavProvider from '~/modules/layout/components/nav/nav-provider';
import { isMobile } from 'is-mobile';
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import DeviceProvider from '~/modules/shared/providers/device.provider';
import type { FooterColumn as StrapiFooterColumn, Keyvalue } from '@cms/types';
import type { Resources } from '~/modules/shared/providers/global-resources.provider';
import GlobalResourcesProvider from '~/modules/shared/providers/global-resources.provider';
import { promiseHash } from 'remix-utils/promise';
import DialogProvider from '~/modules/shared/providers/dialog.provider';
import { useHydrated } from 'remix-utils/use-hydrated';

import '@fontsource/poppins/index.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/500.css';
import poppins400 from '@fontsource/poppins/files/poppins-latin-400-normal.woff2';
import poppins500 from '@fontsource/poppins/files/poppins-latin-500-normal.woff2';
import './tailwind.css';
import type {
  FooterColumn,
  FooterProps,
} from '~/modules/layout/components/nav/footer';
import type { JSXElementConstructor, ReactNode } from 'react';
import { useEffect } from 'react';
import type { TopBarProps } from '~/modules/layout/components/nav/navbar';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { Button } from '~/components/ui/button';
import { gtagClient } from '~/lib/analytics/gtag.client';
import TawkWidget from '~/modules/misc/components/tawk-widget';

export const links: LinksFunction = () => {
  return [
    {
      rel: 'preload',
      href: poppins400,
      as: 'font',
      crossOrigin: 'anonymous',
      type: 'font/woff2',
    },
    {
      rel: 'preload',
      href: poppins500,
      as: 'font',
      crossOrigin: 'anonymous',
      type: 'font/woff2',
    },
    {
      rel: 'preconnect',
      href: 'https://res.cloudinary.com',
    },
    {
      rel: 'preconnect',
      href: 'https://www.googletagmanager.com',
    },
    {
      rel: 'preload',
      href: 'https://www.googletagmanager.com/gtag/js?id=G-TG87K6RF2N',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '32x32',
      href: '/favicon-32x32.png',
      type: 'image/png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '16x16',
      href: '/favicon-16x16.png',
      type: 'image/png',
    },
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: '/favicon.svg',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg',
      color: '#ffcc00',
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const nav = cache.getOrCreate('nav', async () => {
    const [kiyohInfo, main, mobile, footer, top] = await Promise.all([
      kiyoh.getCachedInfo(process.env.KIYOH_LOCATION ?? ''),
      strapi.getMenuBySlug('main'),
      strapi.getMenuBySlug('mobile'),
      strapi.getFooter(),
      strapi.getTopBar(),
    ]);

    const columns: FooterColumn[] = [];
    const addColumn = (column: StrapiFooterColumn | undefined) => {
      if (!column) {
        return;
      }

      columns.push({
        title: column.title ?? '',
        links: column.items.map((item) => ({
          name: item.key,
          href: item.value,
        })),
      });
    };

    addColumn(footer.data.attributes.columnOne);
    addColumn(footer.data.attributes.columnTwo);
    addColumn(footer.data.attributes.columnThree);
    addColumn(footer.data.attributes.columnFour);

    const groupedColumns: [FooterColumn, FooterColumn?][] = [];
    for (let i = 0; i < columns.length; i += 2) {
      const firstColumn = columns[i];
      const secondColumn = columns[i + 1];
      if (secondColumn) {
        groupedColumns.push([firstColumn, secondColumn]);
      } else {
        groupedColumns.push([firstColumn]);
      }
    }

    const footerProps: FooterProps = {
      dynamicContent: footer.data.attributes.dynamicContent,
      groupedColumns,
      socials: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com',
          icon: '/images/facebook.svg',
        },
        {
          name: 'Instagram',
          href: 'https://www.instagram.com',
          icon: '/images/instagram.svg',
        },
        {
          name: 'Pinterest',
          href: 'https://www.pinterest.com',
          icon: '/images/pinterest.svg',
        },
      ],
    };

    const replaceReviewContent = (content: string) => {
      content = content.replace(
        /{{reviews.rating}}/g,
        kiyohInfo.rating?.toString(),
      );

      content = content.replace(
        /{{reviews.numberOfReviews}}/g,
        kiyohInfo.numberOfReviews?.toString(),
      );

      return content;
    };

    const topBar: TopBarProps = {
      aboutLabel: top.data?.attributes.aboutLabel ?? '',
      items:
        top.data?.attributes.items.map((item) => ({
          label: replaceReviewContent(item.label),
          link: item.link,
          showOnMobile: item.showOnMobile,
        })) ?? [],
      configurationEditButton: top.data?.attributes.configurationEditButton,
    };

    const props: NavbarContext = {
      main,
      mobile,
      footer: footerProps,
      topBar,
    };
    return props;
  });

  const kvToMap = (keyValues: Keyvalue[]) => {
    const map: { [key: string]: string } = {};
    keyValues.forEach((kv) => {
      map[kv.key] = kv.value;
    });
    return map;
  };

  // TODO optimize this when middleware is available in Remix
  const globalResources = cache.getOrCreate('globalResources', async () => {
    const [productCard, search] = await Promise.all([
      strapi.getProductCard(),
      strapi.getSearch(),
    ]);
    const resources: Resources = {
      productCard: {
        deliveryTime: productCard.data.attributes.deliveryTime,
        configureButton: productCard.data.attributes.configureButton,
        sampleButton: productCard.data.attributes.sampleButton,
      },
      search: {
        filters: kvToMap(search.data.attributes.filters),
        sorting: kvToMap(search.data.attributes.sorting),
        filtersLabel: search.data.attributes.filtersLabel,
        noResultsFound: search.data.attributes.noResultsFound,
        removeFiltersButton: search.data.attributes.removeFiltersButton,
        sortLabel: search.data.attributes.sortLabel,
        searchResultsShowAllButton:
          search.data.attributes.searchResultsShowAllButton,
        searchResultsNoResultsButton:
          search.data.attributes.searchResultsNoResultsButton,
        searchInputPlaceholder: search.data.attributes.searchInputPlaceholder,
        searchNoResultsMessage: search.data.attributes.searchNoResultsMessage,
        searchResultsTitle: search.data.attributes.searchResultsTitle,
        readMoreButton: search.data.attributes.readMoreButton,
      },
    };
    return resources;
  });

  const ua = request.headers.get('user-agent') ?? '';
  const awaited = await promiseHash({ nav, globalResources });

  return {
    ...awaited,
    isMobile: isMobile({ ua }),
    gaTrackingId: process.env.GA_TRACKING_ID,
    ENV: {
      SENTRY_ENV: process.env.ENVIRONMENT || 'local',
      SENTRY_ENV_NAME: process.env.CLOUDINARY_FOLDER ?? 'dev',
    },
  };
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  let status = 500;
  let data = 'Oh no! Something went wrong!';
  if (isRouteErrorResponse(error)) {
    status = error.status;
    data = error.data ? error.data : data;
  }

  console.error(error);
  captureRemixErrorBoundaryError(error);

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold">{status}</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary sm:text-5xl">
          {status === 404 ? 'Page not found' : 'Something went wrong'}
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">{data}</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button asChild>
            <a href="/">Go back home</a>
          </Button>
        </div>
      </div>
    </main>
  );
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  const location = useLocation();
  const hydrated = useHydrated();

  useEffect(() => {
    if (data?.gaTrackingId?.length) {
      gtagClient.pageview(location.pathname, data.gaTrackingId);
    }
  }, [location, data?.gaTrackingId]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <ProviderStack
          providers={[
            [DeviceProvider, { isMobile: data?.isMobile ?? false }],
            [
              GlobalResourcesProvider,
              { resources: data?.globalResources ?? ({} as any) },
            ],
            [DialogProvider, {}],
            [NavProvider, { nav: data?.nav ?? ({} as any) }],
          ]}
        >
          {children}
          <ScrollRestoration />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${
                data?.ENV ? JSON.stringify(data.ENV) : '{}'
              }`,
            }}
          />
          <Scripts />

          {hydrated && <TawkWidget />}
        </ProviderStack>

        {data?.ENV.SENTRY_ENV === 'production' && !!data?.gaTrackingId && (
          <>
            <script
              defer
              src={`https://www.googletagmanager.com/gtag/js?id=${data.gaTrackingId}`}
            />
            <script
              defer
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
              `,
              }}
            />
          </>
        )}
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}

type NoInfer<T> = [T][T extends any ? 0 : 1];

type ContainsChildren = {
  children?: React.ReactNode;
};

function ProviderStack<
  Providers extends [ContainsChildren, ...ContainsChildren[]],
>({
  providers,
  children,
}: {
  providers: {
    [k in keyof Providers]: [
      JSXElementConstructor<Providers[k]>,
      Omit<NoInfer<Providers[k]>, 'children'>,
    ];
  };
  children: ReactNode;
}) {
  let node = children;

  for (const [Provider, props] of providers) {
    node = <Provider {...props}>{node}</Provider>;
  }

  return node;
}

export const shouldRevalidate = () => false;
